import React from "react";
import { graphql } from "gatsby";
import { DefaultLayout } from "../layouts";
import { Hero, ProductBox, MultiProductBoxWrapper } from "../components";

export type HomePageProps = {
  hero: {
    image: string;
  };
  boxes: {
    color: string;
    title: string;
    image: string;
    description: string;
    slug: string;
  }[];
};

export const HomePageTemplate = ({ hero, boxes }: HomePageProps) => (
  <DefaultLayout>
    <Hero showBox={false} image={hero.image} />
    <MultiProductBoxWrapper>
      {boxes.map((box, index) => (
        <ProductBox {...box} key={index} />
      ))}
    </MultiProductBoxWrapper>
  </DefaultLayout>
);

export const query = graphql`
  {
    home: homeJson {
      hero {
        image
      }
      boxes {
        color
        title
        image
        description
        slug
      }
    }
  }
`;

export default ({ data }: { data: {home: HomePageProps} }) => (
  <HomePageTemplate {...data.home} />
);
